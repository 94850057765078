<template>
  <div class="contact-form fill-height">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3>Contacter le Carrick</h3>

      <form
        ref="formel"
        @submit.prevent="onSubmit"
        method="POST"
        :action="endpoint.endpoint"
      >
        <input type="hidden" name="action" :value="endpoint.action" />
        <input type="hidden" name="form_id" :value="endpoint.formId" />

        <label class="m-s">
          <h5 class="mb-xxs">Message</h5>
          <textarea name="message" v-model="form.message" required></textarea>
        </label>

        <div class="recontact m-s">
          <div class="recontact-main">
            <h5 class="mb-xxs">Je préfère être recontacté par</h5>

            <div class="col mb-s">
              <label class="input-radio">
                <input
                  v-model="form.recontact"
                  name="preference"
                  value="phone"
                  type="radio"
                  required
                />
                <span>Téléphone</span>
              </label>

              <label class="input-radio">
                <input
                  v-model="form.recontact"
                  name="preference"
                  value="email"
                  type="radio"
                  required
                />
                <span>Email</span>
              </label>
            </div>
          </div>

          <div
            class="recontact-phone bt pt-s"
            v-if="form.recontact === 'phone'"
          >
            <h5 class="mb-xxs">Téléphone</h5>
            <FormInput
              name="phone"
              :required="true"
              v-model:value="form.phone"
            />
          </div>

          <div
            class="recontact-email bt pt-s"
            v-if="form.recontact === 'email'"
          >
            <h5 class="mb-xxs">Email</h5>
            <FormInput
              name="email"
              :required="true"
              v-model:value="form.email"
              type="email"
            />
          </div>
        </div>

        <div class="meeting m-s">
          <div class="meeting-main">
            <h5 class="mb-xxs">Je souhaite prendre rendez-vous</h5>

            <div class="col mb-s">
              <label class="input-radio">
                <input
                  v-model="form.meeting"
                  name="meeting"
                  value="yes"
                  type="radio"
                  required
                />
                <span>Oui</span>
              </label>

              <label class="input-radio">
                <input
                  v-model="form.meeting"
                  name="meeting"
                  value="no"
                  type="radio"
                  required
                />
                <span>Non</span>
              </label>
            </div>
          </div>

          <div class="meeting-options">
            <div class="meetingtype bt pt-s" v-if="form.meeting === 'yes'">
              <h5 class="mb-xxs">Je souhaite prendre rendez-vous</h5>

              <label class="input-radio mb-xxs">
                <input
                  v-model="form.meetingtype"
                  name="type"
                  value="physical"
                  type="radio"
                  required
                />
                <span>Physiquement au Carrick (siège du Mouvement)</span>
              </label>

              <label class="input-radio">
                <input
                  v-model="form.meetingtype"
                  name="type"
                  value="call"
                  type="radio"
                  required
                />
                <span>Par visio-conférence</span>
              </label>
            </div>

            <div
              class="meetingdispo pt-s"
              v-if="form.meeting === 'yes' && form.meetingtype"
            >
              <h5 class="mb-xxs">Mes dates de disponibilité</h5>

              <Availability
                number="1"
                v-model:date="form.availability1.date"
                v-model:time="form.availability1.time"
              />

              <Availability
                number="2"
                v-model:date="form.availability2.date"
                v-model:time="form.availability2.time"
              />

              <Availability
                number="3"
                v-model:date="form.availability3.date"
                v-model:time="form.availability3.time"
              />
            </div>
          </div>
        </div>
        <!-- meeting -->

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send">Envoyer</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Votre message est en train d’être envoyé.</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Hey</h4>
        <p>
          Merci ! Ton message a bien été envoyé au Carrick. Tu recevras une
          réponse par email endéans les deux jours ouvrables.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import api from '@/guides/api'
import { list } from '@/guides/endpoints/contact'
import { me } from '@/guides/endpoints/profile'
import { push } from '@/inc/tracking'

import BackBtn from '@/components/BackBtn.vue'
import Alert from '@/components/Alert.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import Availability from '@/components/Availability.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    Availability,
  },
  setup() {
    const showAlert = ref(false)
    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const endpoint = reactive({
      action: '',
      endpoint: '',
      formId: '',
    })

    const form = reactive({
      message: '',
      recontact: '',
      meeting: '',
      meetingtype: '',
      phone: '',
      email: '',
      availability1: {
        date: '',
        time: '',
      },
      availability2: {
        date: '',
        time: '',
      },
      availability3: {
        date: '',
        time: '',
      },
    })

    list().then(r => {
      endpoint.action = r?.data?.content?.form.action
      endpoint.endpoint = r?.data?.content?.form.endpoint
      endpoint.formId = r?.data?.content?.form.formId
    })

    me().then(r => {
      form.phone = r?.data?.phone
      form.email = r?.data?.email
    })

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error
      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)

      push({
        name: 'contact-fail',
        category: 'mail-fail',
        action: 'fail',
        label: 'fail',
      })
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new URLSearchParams()
      const inputs = Object.fromEntries(new FormData(formel.value))

      for (const i in inputs) {
        data.set(i, inputs[i].toString())
      }

      data.set('availability_1_date', form.availability1.date)
      data.set('availability_1_time', form.availability1.time)

      data.set('availability_2_date', form.availability2.date)
      data.set('availability_2_time', form.availability2.time)

      data.set('availability_3_date', form.availability3.date)
      data.set('availability_3_time', form.availability3.time)

      push({
        name: 'contact-submit',
        category: 'mail-submit',
        action: 'submit',
        label: 'submit',
      })

      api
        .post(endpoint.endpoint, data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      showAlert,
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      endpoint,
      formel,
      showError,
      badgeFeedback,
    }
  },
})
</script>

<style lang="scss" scoped>
.contact-form {
  @include form-base;

  background: $c-white;
}

@include mq(l) {
  .recontact {
    display: flex;
    justify-content: space-between;

    .recontact-phone,
    .recontact-email {
      padding-top: 0;
      border-top: 0;
    }

    > * {
      flex-basis: 50%;
    }
  }

  .meeting {
    display: flex;
    justify-content: space-between;

    .meetingtype {
      border-top: 0;
      padding-top: 0;
    }

    > * {
      flex-basis: 50%;
    }
  }
}
</style>
