
import { defineComponent, ref, reactive } from 'vue'
import api from '@/guides/api'
import { list } from '@/guides/endpoints/contact'
import { me } from '@/guides/endpoints/profile'
import { push } from '@/inc/tracking'

import BackBtn from '@/components/BackBtn.vue'
import Alert from '@/components/Alert.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import Availability from '@/components/Availability.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    Availability,
  },
  setup() {
    const showAlert = ref(false)
    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const endpoint = reactive({
      action: '',
      endpoint: '',
      formId: '',
    })

    const form = reactive({
      message: '',
      recontact: '',
      meeting: '',
      meetingtype: '',
      phone: '',
      email: '',
      availability1: {
        date: '',
        time: '',
      },
      availability2: {
        date: '',
        time: '',
      },
      availability3: {
        date: '',
        time: '',
      },
    })

    list().then(r => {
      endpoint.action = r?.data?.content?.form.action
      endpoint.endpoint = r?.data?.content?.form.endpoint
      endpoint.formId = r?.data?.content?.form.formId
    })

    me().then(r => {
      form.phone = r?.data?.phone
      form.email = r?.data?.email
    })

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error
      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)

      push({
        name: 'contact-fail',
        category: 'mail-fail',
        action: 'fail',
        label: 'fail',
      })
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new URLSearchParams()
      const inputs = Object.fromEntries(new FormData(formel.value))

      for (const i in inputs) {
        data.set(i, inputs[i].toString())
      }

      data.set('availability_1_date', form.availability1.date)
      data.set('availability_1_time', form.availability1.time)

      data.set('availability_2_date', form.availability2.date)
      data.set('availability_2_time', form.availability2.time)

      data.set('availability_3_date', form.availability3.date)
      data.set('availability_3_time', form.availability3.time)

      push({
        name: 'contact-submit',
        category: 'mail-submit',
        action: 'submit',
        label: 'submit',
      })

      api
        .post(endpoint.endpoint, data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      showAlert,
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      endpoint,
      formel,
      showError,
      badgeFeedback,
    }
  },
})
